import {
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { logger } from 'logs-api-client';
import Breadcrumb, { IBreadcrumb } from '@/components/Breadcrumb.vue';
import Hint from '@/components/Hint.vue';
import RestrictionPage from '@/components/RestrictionPage.vue';
import Tabs, { IitensTabs } from '@/components/Tabs.vue';
import PaginationTable, { IitensPagination } from '@/components/PaginationTable.vue';
import SessionMixin from '@/mixins/session';
import TableInvoiceReceipts from './components/TableInvoiceReceipts.vue';
import SkeletonInvoiceReceipts from './components/SkeletonInvoiceReceipts.vue';

interface DocumentsItem {
  type: string;
  url: string;
}

interface DataItem {
  referenceDate: string,
  dueDate: string,
  paymentMethod: string,
  value: string,
  situation: string,
  contractId: string,
  documents: DocumentsItem[],
}

interface GrupedData {
  label: string,
  month: string,
  year: string,
  data: DataItem[],
}

@Component({
  components: {
    Breadcrumb,
    Hint,
    TableInvoiceReceipts,
    RestrictionPage,
    Tabs,
    PaginationTable,
    SkeletonInvoiceReceipts,
  },
})

export default class PageContracts extends mixins(SessionMixin) {
  @Prop({ type: Array, required: false }) readonly selectedItems!: any[];

  public maestroContextType = (window as any).maestroContextType;

  private localSelectedItems: any[] = this.selectedItems;

  private data: DataItem[] = []

  private urlsCache = new Map<string, string>();

  private activeTabIndex = -1;

  private lastClickedTabIndex = 0;

  private currentTableItems: any[] = [];

  private groupsArray: any[] = [];

  public currentPage = 1;

  private totalItems = 0;

  private itemsOnPage = 0;

  private itemsCountForCurrentPage = 0;

  private showRestrictionPage = false;

  private itemDocument: any[] = [];

  private iconsByType: any[] = [];

  private isDisabledActionButton = false;

  private disabledSituations: string[] = [];

  private referenceDateValue = '';

  private dueDateValue = '';

  private contractIdValue = '';

  private formatedDateValue = 0;

  private selectedCount = 0;

  private isLoadingSkeleton = true;

  private isDescending = false;

  private isActiveIcon = false;

  private areAllChecked = false;

  private isChecked = true;

  private touched = false;

  public types = [this.$t('INVOICES_ZCC.FILTERS.ALL_TYPES'), this.$t('INVOICES_ZCC.FILTERS.SVA'), this.$t('INVOICES_ZCC.FILTERS.LICENSING')];

  public statuses = [this.$t('INVOICES_ZCC.FILTERS.ALL_STATUSES'), this.$t('INVOICES_ZCC.FILTERS.Pago'), this.$t('INVOICES_ZCC.FILTERS.Pendente')];

  public months = [{ label: this.$t('INVOICES_ZCC.FILTERS.ALL_MONTHS'), month: this.$t('INVOICES_ZCC.FILTERS.ALL_MONTHS') }];

  public unfilteredData: any[] = [];

  public country = '';

  public contractFilter = {
    number: '',
    month: this.months[0].label,
    type: this.types[0],
    status: this.statuses[0],
  };

  public clearFilter() {
    this.contractFilter.number = '';
  }

  @Watch('contractFilter', { deep: true })
  private changeFilterValue(newValue: any) {
    this.touched = true;
    this.contractFilter = newValue;
  }

  get filteredData() {
    if (!this.touched) {
      return this.unfilteredData;
    }

    return this.unfilteredData?.filter((item) => {
      const contractFilterMatches = !this.contractFilter.number || item.contractId.includes(this.contractFilter.number);
      const typeFilterMatches = this.contractFilter.type === this.$t('INVOICES_ZCC.FILTERS.ALL_TYPES') || item.typeDocumento === this.contractFilter.type;
      const statusFilterMatches = this.contractFilter.status === this.$t('INVOICES_ZCC.FILTERS.ALL_STATUSES') || item.situation === this.contractFilter.status;
      const monthFilterMatches = this.contractFilter.month === this.$t('INVOICES_ZCC.FILTERS.ALL_MONTHS') || item.dueDate.slice(3, 5) === this.contractFilter.month;

      return contractFilterMatches && typeFilterMatches && statusFilterMatches && monthFilterMatches;
    });
  }

  private async getRecipts() {
    try {
      const options = {};
      const { data } = await this.$http.get(this.url, options);
      this.data = data;
      this.createUnfilteredData(data);
    } catch (error: any) {
      this.isLoadingSkeleton = false;
      logger.error(`${error}`, error);
    } finally {
      this.isLoadingSkeleton = false;
    }
  }

  private get url() {
    return `${process.env.VUE_APP_PAYMENT_HISTORY_URL_V3}`;
  }

  created() {
    this.getRecipts();
    this.getCustomerCurrency();
  }

  public get canSeeRestriction(): boolean {
    return !(Number(this.mxSession_profile.id) <= 2) && this.showRestrictionPage;
  }

  createGroupsFromData(data: DataItem[]): GrupedData[] {
    const groups: GrupedData[] = [];
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;

    function sortGroups(groupA: any, groupB: any) {
      if (groupA.year === currentYear && groupA.month === currentMonth) {
        return -1;
      }
      if (groupB.year === currentYear && groupB.month === currentMonth) {
        return 1;
      }
      if (groupA.year !== groupB.year) {
        return groupA.year - groupB.year;
      }
      return groupA.month - groupB.month;
    }

    data.forEach((item: any) => {
      const referenceDateParts = item.referenceDate.split('-');
      const year = referenceDateParts[0];
      const month = referenceDateParts[1];

      const monthKeys = [
        'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
        'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER',
      ];

      const translatedMonthKey = monthKeys[parseInt(month, 10) - 1];
      const monthName = this.$t(`INVOICES_RECEIPTS.MONTHS_NAME.${translatedMonthKey}`).toString();

      const existingGroup = groups.find((group: any) => group.year === year && group.month === month);

      if (existingGroup) {
        existingGroup.data.push(item);
      } else {
        const newGroup: GrupedData = {
          label: monthName,
          month,
          year,
          data: [item],
        };
        groups.push(newGroup);
      }
    });

    groups.sort(sortGroups);

    let recentGroups;

    if (groups.length > 6) {
      recentGroups = groups.slice(-6);
    } else {
      recentGroups = groups;
    }

    return recentGroups;
  }

  public get tabsItem(): IitensTabs[] {
    this.groupsArray = this.createGroupsFromData(this.data);
    // @ts-ignore
    this.groupsArray?.map((item) => this.months.push({ month: item.month, label: item.label }));

    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');

    const tabsItemArray: IitensTabs[] = this.groupsArray?.map((group, i) => {
      const isLastMonth = i === this.groupsArray.length - 1;
      const isActive = this.activeTabIndex === i || (this.activeTabIndex === -1 && isLastMonth);
      let tabLabel = group.label;
      let track = `invoices-filter_tab-${this.groupsArray.length - i}_months_ago`;

      if (group.month === currentMonth) {
        tabLabel = `${group.label} (${this.$t('INVOICES_RECEIPTS.MONTHS_NAME.CURRENT')})`;
        track = 'invoices-filter_tab-these_month';
      }

      return {
        label: tabLabel,
        active: isActive,
        dataTrack: track,
        clickTab: () => this.handleTabClick(i),
      };
    });

    if (this.activeTabIndex === -1) {
      this.activeTabIndex = this.groupsArray.length - 1;
    }
    this.handleTabClick(this.activeTabIndex);

    return tabsItemArray;
  }

  handleTabClick(index: number): void {
    this.activeTabIndex = index;

    if (this.lastClickedTabIndex !== this.activeTabIndex) {
      this.currentPage = 1;
      this.lastClickedTabIndex = this.activeTabIndex;
    }

    if (this.activeTabIndex >= 0 && this.activeTabIndex < this.groupsArray.length) {
      this.currentTableItems = this.groupsArray[this.activeTabIndex].data;
    }

    this.lastClickedTabIndex = this.activeTabIndex;
    this.clearSelectedCheckboxes();
  }

  selectAllCheckboxes() {
    this.areAllChecked = !this.areAllChecked;
    const itens: any[] = this.tableItens;

    itens.forEach((item: any) => {
      if (!item.isDisabledCheckbox) {
        this.$set(item, 'isChecked', this.areAllChecked);
      }
    });

    this.localSelectedItems = itens?.filter((item: any) => !item.isDisabledCheckbox);
    this.countSelectedCheckboxes();
  }

  toggleCheckbox(index: number): void {
    const item = this.tableItens[index];
    // @ts-ignore
    if (!item.isDisabledCheckbox) {
      item.isChecked = !item.isChecked;

      this.localSelectedItems = this.tableItens?.filter((item) => item.isChecked);
      this.countSelectedCheckboxes();
    }
  }

  countSelectedCheckboxes(): void {
    this.selectedCount = this.tableItens?.filter((item) => item.isChecked).length;
  }

  clearSelectedCheckboxes(): void {
    this.selectedCount = 0;
    this.clearAllCheckboxes();
  }

  clearAllCheckboxes() {
    this.areAllChecked = false;
    this.tableItens.forEach((item: any, index: number) => {
      const updatedItem = { ...item };
      updatedItem.isChecked = false;
      this.$set(this.tableItens, index, updatedItem);
    });
  }

  private extractValuesByType(itemDocument: any[], targetType: string): { types: string[], urls: string[] } {
    const types: string[] = [];
    const urls: string[] = [];

    itemDocument
      ?.filter((item: any) => item.type === targetType)
      .forEach((item: any) => {
        types.push(item.type);
        urls.push(item.url);
      });

    return { types, urls };
  }

  public createUnfilteredData(data: any[]) {
    // @ts-ignore
    data?.map((item) => this.unfilteredData.push(this.transformItem(item)));
  }

  public get tableItens(): any[] {
    if (this.lastClickedTabIndex === -1) {
      const lastItemIndex = this.groupsArray.length - 1;
      const lastItemData = this.groupsArray[lastItemIndex];
      return this.getItemsToDisplay(lastItemData)?.map((item: any) => this.transformItem(item));
    }

    const lastClickedTabData = this.groupsArray[this.lastClickedTabIndex];

    if (!lastClickedTabData) {
      return [];
    }

    const itemsToDisplay = this.getItemsToDisplay(lastClickedTabData);
    const transformedItems = itemsToDisplay?.map((item: any) => this.transformItem(item));

    return transformedItems?.filter((item) => {
      const contractFilterMatches = !this.contractFilter.number || item.contractId.includes(this.contractFilter.number);
      const typeFilterMatches = this.contractFilter.type === this.$t('INVOICES_ZCC.FILTERS.ALL_TYPES') || item.typeDocumento === this.contractFilter.type;
      const statusFilterMatches = this.contractFilter.status === this.$t('INVOICES_ZCC.FILTERS.ALL_STATUSES') || item.situation === this.contractFilter.status;
      const monthFilterMatches = this.contractFilter.month === this.$t('INVOICES_ZCC.FILTERS.ALL_MONTHS') || item.dueDate.slice(3, 5) === this.contractFilter.month;

      return contractFilterMatches && typeFilterMatches && statusFilterMatches && monthFilterMatches;
    });
  }

  private getItemsToDisplay(lastClickedTabData: any): any[] {
    const itemsPerPage = 10;
    const startIndex = (this.currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return lastClickedTabData.data.slice(startIndex, endIndex);
  }

  private async getCustomerCurrency() {
    try {
      const { data } = await this.$http.get(this.urlCustomerCurrency);
      this.country = data?.country?.toString();
    } catch (error: any) {
      logger.error(`${error}`, error);
    }
  }

  private get urlCustomerCurrency() {
    return `${process.env.VUE_APP_CUSTOMER_PERSONAL_DATA_CONTEXT}`;
  }

  private transformItem(item: any): any {
    const situationInfo = this.getSituationInfo(item.situation, item.documents);
    const documentInfo = this.getDocumentInfo(item.documents, item.situation);
    this.referenceDateValue = item.referenceDate;
    this.dueDateValue = item.dueDate;

    const formatDate = (date: string, locale = navigator.language) => {
      if (!date) {
        return '';
      }

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC',
      };
      return new Date(date).toLocaleDateString(locale, options);
    };

    const formatNumber = (stringValue: string) => {
      const numberValue = parseFloat(stringValue);
      const formattedNumber = numberValue.toLocaleString(navigator.language, {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formattedNumber;
    };

    const formattedValue = formatNumber(item.value);

    const formatedDate = formatDate(this.dueDateValue);

    return {
      contractId: item.contractId,
      typeDocumento: documentInfo.labelType,
      // eslint-disable-next-line no-nested-ternary
      value: (this.country === 'MEX' ? `$ ${formattedValue}` : (this.country === 'GTM' ? `USD ${formattedValue}` : `R$ ${formattedValue}`)),
      dueDate: formatedDate,
      situation: situationInfo.situationText,
      situationType: situationInfo.situationType,
      iconDetailedConsumption: documentInfo.iconsByType.detailedConsumption,
      iconBill: documentInfo.iconsByType.bill,
      iconInvoice: documentInfo.iconsByType.invoice,
      downloadBill: () => this.downloadItem(documentInfo.iconsByType.bill.url, documentInfo.iconsByType.bill.type, item.contractId, item.dueDate),
      downloadDetailedConsumption: () => this.downloadItem(documentInfo.iconsByType.detailedConsumption.url, documentInfo.iconsByType.detailedConsumption.type, item.contractId, item.dueDate),
      downloadInvoice: () => this.downloadItem(documentInfo.iconsByType.invoice.url, documentInfo.iconsByType.invoice.type, item.contractId, item.dueDate),
      isDisabledCheckbox: situationInfo.disableCheckbox,
      isChecked: false,
    };
  }

  private getDocumentInfo(itemDocument: any[], situation: string): any {
    const billValues = this.extractValuesByType(itemDocument, 'bill');
    const invoiceValues = this.extractValuesByType(itemDocument, 'invoice');
    const invoiceSVA = this.extractValuesByType(itemDocument, 'invoiceSVA');
    const mxFacturaValues = this.extractValuesByType(itemDocument, 'MX_FACTURA');
    const arFacturaValues = this.extractValuesByType(itemDocument, 'AR_FACTURA');
    const mxXmlFacturaValues = this.extractValuesByType(itemDocument, 'MX_XML_FATURA');

    let labelType = '';
    let billUrl = '';
    let invoiceUrl = '';
    let typeDocumentInvoice = '';
    let typeDocumentBill = '';
    const invoiceDocumentsFiltered = itemDocument?.filter((doc) => doc.type && doc.type.startsWith('Invoice_'));

    const invoiceUrls = invoiceDocumentsFiltered?.map((doc) => doc.url)?.filter((url) => url);
    const invoiceTypes = invoiceDocumentsFiltered?.map((doc) => doc.type)?.filter((type) => type);

    if (invoiceSVA.urls && invoiceSVA.urls.length > 0) {
      billUrl = invoiceSVA.urls.toString();
      labelType = `${this.$t('FISCAL_DOCUMENTS.PAYMENT_HISTORY.INVOICE_SVA')}`;
      typeDocumentBill = invoiceSVA.types.toString();
    } else if (billValues.urls && billValues.urls.length > 0) {
      billUrl = billValues.urls.toString();
      labelType = `${this.$t('FISCAL_DOCUMENTS.PAYMENT_HISTORY.LICENSING')}`;
      typeDocumentBill = billValues.types.toString();
    }

    if (mxFacturaValues.urls && mxFacturaValues.urls.length > 0) {
      invoiceUrl = mxFacturaValues.urls.toString();
      typeDocumentInvoice = mxFacturaValues.types.toString();
      labelType = `${this.$t('FISCAL_DOCUMENTS.PAYMENT_HISTORY.LICENSING')}`;
    } else if (arFacturaValues.urls && arFacturaValues.urls.length > 0) {
      invoiceUrl = arFacturaValues.urls.toString();
      labelType = `${this.$t('FISCAL_DOCUMENTS.PAYMENT_HISTORY.LICENSING')}`;
      typeDocumentInvoice = arFacturaValues.types.toString();
    } else if (invoiceUrls.length > 0) {
      labelType = `${this.$t('FISCAL_DOCUMENTS.PAYMENT_HISTORY.LICENSING')}`;
      invoiceUrl = invoiceUrls.join(', ');
      typeDocumentInvoice = invoiceTypes.join(', ');
    } else if (invoiceValues.urls && invoiceValues.urls.length > 0) {
      labelType = `${this.$t('FISCAL_DOCUMENTS.PAYMENT_HISTORY.LICENSING')}`;
      invoiceUrl = invoiceValues.urls.toString();
      typeDocumentInvoice = invoiceValues.types.toString();
    }
    const disabledIcon = this.disabledSituations.includes(situation);

    const iconsByType = {
      detailedConsumption: {
        class: 'fa-light fa-file-lines',
        label: '',
        url: mxXmlFacturaValues.urls.toString(),
        type: mxXmlFacturaValues.types.toString(),
        isDisabled: disabledIcon || mxXmlFacturaValues.types.toString() === '',
      },
      bill: {
        class: 'fa-light fa-file-invoice-dollar',
        label: '',
        url: billUrl,
        type: typeDocumentBill,
        isDisabled: disabledIcon || billUrl === '',
      },
      invoice: {
        class: 'fa-regular fa-receipt',
        label: '',
        url: invoiceUrl,
        type: typeDocumentInvoice,
        isDisabled: disabledIcon || invoiceUrl === '',
      },
    };

    return {
      iconsByType,
      labelType,
    };
  }

  private getSituationInfo(situation: string, itemDocument: any[]): any {
    const situationTextMapping: any = {
      QUITADO: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.PAID',
      CANCELADO: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.CANCELED',
      REEMITIDO: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.RESENT',
      EM_ABERTO: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.OPENED',
      DOWNLOADED: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.DOWNLOADED',
      PARCIAL: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.PARTIALLY',
      PERDA: 'FISCAL_DOCUMENTS.PAYMENT_HISTORY.STATUS.LOSS',
    };

    const situationTypeMapping: any = {
      QUITADO: 'success',
      CANCELADO: 'danger',
      REEMITIDO: 'info',
      EM_ABERTO: 'warning',
      DOWNLOADED: 'warning',
      PARCIAL: 'warning',
      PERDA: 'warning',
    };

    const billValues = this.extractValuesByType(itemDocument, 'bill');
    const invoiceValues = this.extractValuesByType(itemDocument, 'invoice');
    const invoiceSVA = this.extractValuesByType(itemDocument, 'invoiceSVA');
    const mxFacturaValues = this.extractValuesByType(itemDocument, 'MX_FACTURA');
    const arFacturaValues = this.extractValuesByType(itemDocument, 'AR_FACTURA');
    const mxXmlFacturaValues = this.extractValuesByType(itemDocument, 'MX_XML_FATURA');

    const invoiceDocumentsFiltered = itemDocument?.filter((doc) => doc.type && doc.type.startsWith('Invoice_'));

    const invoiceTypes = invoiceDocumentsFiltered?.map((doc) => doc.type)?.filter((type) => type);

    const isSituationDisabled = this.disabledSituations.includes(situation);
    this.disabledSituations = ['CANCELADO'];
    const invoiceSVAEmpty = invoiceSVA.types.toString() === '';
    const billEmpty = billValues.types.toString() === '';
    const invoiceEmpty = invoiceValues.types.toString() === '';
    const mxFactura_empty = mxFacturaValues.types.toString() === '';
    const arFactura_empty = arFacturaValues.types.toString() === '';
    const mxXmlFactura_empty = mxXmlFacturaValues.types.toString() === '';
    const invoiceTypes_empty = invoiceTypes.join(', ').toString() === '';

    const disableCheckbox = isSituationDisabled || (invoiceSVAEmpty && billEmpty && invoiceEmpty && arFactura_empty && mxXmlFactura_empty && mxFactura_empty && invoiceTypes_empty);

    const situationTextKey = situationTextMapping[situation] || situation;
    const situationText = this.$t(situationTextKey);

    const situationType = situationTypeMapping[situation] || 'default';

    return { situationText, situationType, disableCheckbox };
  }

  private calculateLabelType(iconsByType: any): string {
    let labelType = '';

    Object.values(iconsByType).forEach((icon: any) => {
      if (icon.isDisabled) {
        labelType = 'Disabled';
      }
    });

    return labelType;
  }

  private handleDownloadAction(resultString: string): void {
    const lines = resultString.split('\n');

    const extractedInfo = lines?.map((line) => {
      const [url, type] = line.split(': ');
      return {
        url,
        type,
      };
    });
    const dueDate = this.dueDateValue;
    const contractId = this.contractIdValue;

    extractedInfo.forEach((
      {
        url,
        type,
      },
    ) => {
      this.downloadItem(
        url,
        type,
        contractId,
        dueDate,
      );
    });
  }

  private handlerDownloadActionBill(resultString: string): void {
    this.handleDownloadAction(resultString);
  }

  private handlerDownloadActionInvoice(resultString: string): void {
    this.handleDownloadAction(resultString);
  }

  private handlerDownloadActionDetailedConsumption(resultString: string): void {
    this.handleDownloadAction(resultString);
  }

  public get itensPagination(): IitensPagination[] {
    const itemsPerPage = 10;
    const arrayPage = this.groupsArray[this.activeTabIndex];

    this.totalItems = arrayPage.data.length;
    const totalPages = Math.ceil(this.totalItems / itemsPerPage);

    const paginationArray: IitensPagination[] = [];

    for (let i = 1; i <= totalPages; i++) {
      const startIndex = (i - 1) * itemsPerPage;
      const endIndex = Math.min(startIndex + itemsPerPage, this.totalItems);
      this.itemsOnPage = endIndex - startIndex;

      paginationArray.push({
        current: i === this.currentPage,
        ariaLabel: `Page ${i}`,
        ariaCurrent: 'Page',
        numberPage: i,
        itemsCount: this.itemsOnPage,
        clickPage: () => this.changePage(i),
      });
    }

    const currentPageObject = paginationArray.find((page: any) => page.numberPage === this.currentPage);
    if (currentPageObject) {
      this.itemsCountForCurrentPage = currentPageObject.itemsCount;
    }
    return paginationArray;
  }

  public changePage(pageNumber: number): void {
    this.currentPage = pageNumber;
    window.scrollTo(0, 0);
  }

  private async downloadItem(url: string, type: string, contractId: string, dueDate: string): Promise<void> {
    try {
      const fileURL = await this.getFileUrl(url, type);

      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const date = dueDate.replace(/-/g, '');

      let fileExtension = '';
      let typeColumnItem = '';
      if (type === 'MX_XML_FATURA') {
        fileExtension = 'xml';
        typeColumnItem = 'descritivo';
      } else if (type === 'bill' || type === 'invoiceSVA') {
        fileExtension = 'pdf';
        typeColumnItem = 'ticket';
      } else {
        fileExtension = 'pdf';
        typeColumnItem = 'invoice';
      }

      fileLink.setAttribute('download', `${contractId}_${type.toLowerCase()}_${typeColumnItem}_${date}.${fileExtension}`);

      if (type === 'MX_XML_FATURA') {
        fileLink.setAttribute('target', '_self');
      }

      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    } catch (error) {
      if (error === 'ECONNABORTED') {
        this.$alert.warning({
          title: `${this.$t('MESSAGES.DOCUMENT_TIMEOUT.TITLE')}`,
          message: `${this.$t('MESSAGES.DOCUMENT_TIMEOUT.MESSAGE')}`,
          duration: 7000,
        });
        return;
      }
      this.$alert.danger({
        title: `${this.$t('MESSAGES.ATTEMPTION')}`,
        message: `${this.$t('MESSAGES.ERROR_TRY_AGAIN')}`,
        duration: 5000,
      });
    }
  }

  private async getFileUrl(url: string, type: string): Promise<string> {
    let fileURL = this.urlsCache.get(url);

    if (!fileURL) {
      const { data } = await this.$http.get(url, { responseType: 'blob', timeout: 30 * 1000 });

      fileURL = window.URL.createObjectURL(new Blob([data], { type: `application/${type}` }));

      this.urlsCache.set(url, fileURL);
    }
    return fileURL;
  }

  private tabelaHeaders = [
    {
      label: '',
      type: 'checkbox',
      id: 'idCheckbox',
      name: 'checkAll',
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.CONTRACT'),
      key: 'contractId',
      icon: 'fa-light fa-sort',
      isIconActive: this.isDescending,
      SortDescendingContracts: () => this.reverseItemsContracts(),
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.CONTRACT_NAME'),
      key: 'typeDocumento',
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.VALUE'),
      key: 'value',
      icon: 'fa-light fa-sort',
      SortDescendingValue: () => this.reverseItemsValue(),
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.EXPIRATION'),
      key: 'dueDate',
      icon: 'fa-light fa-sort',
      SortDescendingDueDate: () => this.reverseItemsDueDate(),
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.STATUS'),
      key: 'situation',
      type: 'badge',
      icon: 'fa-light fa-sort',
      SortDescendingStatus: () => this.reverseItemsSituation(),
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.DESCRIPTIVE'),
      key: 'detailedConsumption',
      type: 'isIcon',
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.INVOICE'),
      key: 'invoice',
      type: 'isIcon',
    },
    {
      label: this.$t('INVOICES_RECEIPTS.TABLE.HEADER.TICKET'),
      key: 'bill',
      type: 'isIcon',
    },
  ];

  public reverseItemsContracts(): void {
    this.isActiveIcon = !this.isActiveIcon;
    if (this.isDescending) {
      this.data.sort((a: any, b: any) => a.contractId - b.contractId);
    } else {
      this.data.sort((a: any, b: any) => b.contractId - a.contractId);
    }

    this.isDescending = !this.isDescending;
  }

  public reverseItemsValue(): void {
    if (this.isDescending) {
      this.data.sort((a: any, b: any) => parseFloat(a.value) - parseFloat(b.value));
    } else {
      this.data.sort((a: any, b: any) => parseFloat(b.value) - parseFloat(a.value));
    }

    this.isDescending = !this.isDescending;
  }

  public reverseItemsDueDate(): void {
    if (this.isDescending) {
      this.data.sort((a: any, b: any) => {
        const dateA = new Date(a.dueDate);
        const dateB = new Date(b.dueDate);
        return dateB.getTime() - dateA.getTime();
      });
    } else {
      this.data.sort((a: any, b: any) => {
        const dateA = new Date(a.dueDate);
        const dateB = new Date(b.dueDate);
        return dateA.getTime() - dateB.getTime();
      });
    }

    this.isDescending = !this.isDescending;
  }

  public reverseItemsSituation(): void {
    if (this.isDescending) {
      this.data.sort((a: any, b: any) => b.situation.localeCompare(a.situation));
    } else {
      this.data.sort((a: any, b: any) => a.situation.localeCompare(b.situation));
    }

    this.isDescending = !this.isDescending;
  }

  public get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        labelLink: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.HOME')}`,
        path: '/welcome',
      },
      {
        label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.CUSTOMER_AREA')}`,
        to: '/preferences/my-account',
      },
      {
        label: `${this.$t('INVOICES_RECEIPTS.BREADCRUMBS.INVOICES')}`,
        to: '#',
      },
    ];
  }
}
