import Vue from 'vue';
import VueI18n from 'vue-i18n';

import messages from './locales';
import { dateTimeFormats, numberFormats } from './formats';

Vue.use(VueI18n);

const validateLocale = (lang: string) => lang in messages;

const language = navigator.language.split('-')[0];
const defaultLocale: string = validateLocale(language) ? language : 'en';

const i18n = new VueI18n({
  locale: defaultLocale,
  // @ts-ignore
  dateTimeFormats,
  // @ts-ignore
  numberFormats,
  fallbackLocale: defaultLocale,
  messages,
});

export const setLocale = (newLanguage: string) => {
  const validate = validateLocale(newLanguage);
  let response = {
    status: 'error',
    message: `locale not found, default locale [${defaultLocale}] loaded`,
  };

  if (validate) {
    response = {
      status: 'success',
      message: `locale changed from [${i18n.locale}] to [${newLanguage}]`,
    };
  }

  i18n.locale = validate ? newLanguage : defaultLocale;
  return response;
};

export default i18n;
