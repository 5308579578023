
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ZccContractStore from '@/store/modules/zccContract';
import { logger } from 'logs-api-client';
// @ts-expect-error:
import customerDataService from '@platform/customer-data-service';
import PlanTypes from '@/types/zcc-plan';
import { IContractZcc } from '@/types/contract-zcc';
import CardSoftwarePlanSummary from './CardSoftwarePlanSummary.vue';
import CardChannelPlanSummary from './CardChannelPlanSummary.vue';

enum StatusBadges {
  ACTIVE = 'is-light is-success',
  BLOCKED = 'is-light is-danger',
  CANCELLED = 'is-light is-danger',
  CHARGE_BACK = 'is-light is-danger',
  CONTRACT_TERMINATION = 'is-light is-danger',
  CHANGED = 'is-light is-warning',
  WAITING_ACTIVATION = 'is-light is-warning',
  WAITING_CANCELLATION = 'is-light is-warning',
  InApprovalProcess = 'is-light is-warning',
  Draft = '',
  Activated = 'is-light is-success',
  Cancelado = 'is-light is-danger',
  Rescindido = 'is-light is-danger',
  Bloqueado = 'is-light is-danger'
}

enum PaymentMethodsBadges {
  BOLETO = 'fa-regular fa-regular fa-file',
  CREDIT_CARD = 'fa-regular fa-credit-card',
  DEPOSIT = 'fa-regular fa-piggy-bank',
  Transferência = 'fa-regular fa-money-bill-transfer',
}

enum BadgeIcons {
  ACTIVE = 'fak fa-success-medium',
  BLOCKED = 'fa-regular fa-circle-minus',
  CANCELLED = 'fa-regular fa-circle-minus',
  CHARGE_BACK = 'fa-regular fa-circle-minus',
  CONTRACT_TERMINATION = 'fa-regular fa-circle-minus',
  CHANGED = 'fak fa-warning-medium',
  WAITING_ACTIVATION = 'fak fa-warning-medium',
  WAITING_CANCELLATION = 'fak fa-warning-medium',
}

const zccContract = namespace('zccContract');

@Component({
  components: {
    CardSoftwarePlanSummary,
    CardChannelPlanSummary,
  },
})
export default class Contracts extends Vue {
  public statusBadges;

  public paymentMethodBadges;

  public badgeIcons;

  canViewUpgradeDowngrade = false;

  // eslint-disable-next-line
  constructor(vueInstance: any) {
    super();
    this.statusBadges = StatusBadges;
    this.paymentMethodBadges = PaymentMethodsBadges;
    this.badgeIcons = BadgeIcons;
  }

  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly contract!: IContractZcc;

  @Prop({
    required: false,
    default: null,
    type: Function,
  }) readonly onSelected!: (newContract: IContractZcc) => void;

  public showChangePlanOption = false;

  public myWalletUrl = process.env.VUE_APP_MY_WALLET;

  isEnterprisePlan = false;

  @zccContract.Mutation
  public setZccContract!: (contract: ZccContractStore) => void;

  async created() {
    const canViewFeature = await this.$canIViewFeature({ name: 'change-plan' });
    const isAppContext = (window as any).maestroContextType === 'APP';
    this.showChangePlanOption = isAppContext && canViewFeature;
  }

  public formatDate = (date: string, locale = navigator.language) => {
    if (!date) {
      return '';
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    };
    return new Date(date).toLocaleDateString(locale, options);
  };

  public contractSelected() {
    if (this.onSelected && typeof this.onSelected === 'function') {
      this.onSelected(this.contract);
    }
  }

  public formatContractName(name: string) {
    const length = 30;
    return name.length > length ? `${name.slice(0, length)}...` : name;
  }

  public getStatusBadgeClass() {
    return this.statusBadges[this.contract.status.replace(/ /g, '') as keyof typeof this.statusBadges];
  }

  public getPaymentMethodIconClass() {
    return this.paymentMethodBadges[this.contract.paymentMethod as keyof typeof this.paymentMethodBadges];
  }

  public getBadgeIconClass() {
    return this.badgeIcons[this.contract.status as keyof typeof this.badgeIcons];
  }

  public changePlanRedirect() {
    this.$router.push('/plans-and-contracts/change-plan');
  }

  public goToConsumptionPage() {
    window.open('/analysis/consumption', '_blank');
  }

  async checkIfIsEnterprisePlan(): Promise<void> {
    try {
      const { suitePlanChannel, suitePlanSoftware } = await customerDataService.getContext();
      if (suitePlanSoftware.toUpperCase().includes(PlanTypes.ENTERPRISE) || suitePlanChannel.toUpperCase().includes(PlanTypes.ENTERPRISE)) {
        this.isEnterprisePlan = true;
      }
    } catch (error: any) {
      logger.error(`${error}`, error);
    }
  }

  async mounted() {
    await this.checkIfIsEnterprisePlan();
  }
}

