
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import SessionMixin from '@/mixins/session';

import AsyncProvider from '@/components/AsyncProvider.vue';
import Skeleton from '@/components/Skeleton.vue';
import Hint from '@/components/Hint.vue';

import { IAddress } from '@/types/address';

import Address from './Address.vue';
import ModalAddress from './ModalAddress.vue';

@Component({
  components: {
    AsyncProvider,
    Hint,
    Skeleton,
    Address,
    ModalAddress,
  },
})
export default class TabAddress extends mixins(SessionMixin) {
  @Prop({
    required: true,
    default: 'my-account',
    type: String,
  }) readonly activeTab!: string;

  public get url(): string {
    return `${process.env.VUE_APP_CUSTOMER_PERSONAL_DATA_URL}/v1/address`;
  }

  public get params() {
    return { organizationId: this.mxSession_organization.id };
  }

  private get addressProvider(): AsyncProvider {
    return this.$refs.addressProvider as AsyncProvider;
  }

  public get canEditAddress(): boolean {
    return (Number(this.mxSession_profile.id) <= 2);
  }

  public onAddressUpdated() {
    this.addressProvider.requestData({ forceRequest: true });
  }

  public normalizeAddressFields(data: IAddress): IAddress | void {
    return {
      ...data,
      addressLine2: data.addressLine2 ?? '',
    };
  }
}

