
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
// @ts-expect-error:
import customerDataService from '@platform/customer-data-service';
// @ts-expect-error:
import FeedbackService from '@platform/feedback-service';
// @ts-expect-error:
import autentication from '@platform/authentication';
import SelectLanguage from '@/components/form/SelectLanguage.vue';
import SessionMixin from '@/mixins/session';
import { logger } from 'logs-api-client';
import { IUser } from '@/types/user';

@Component({
  components: {
    SelectLanguage,
  },
})

export default class TabMyAccount extends mixins(SessionMixin) {
  @Prop({
    required: true,
    default: 'my-account',
    type: String,
  }) readonly activeTab!: string;

  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly user!: IUser;

  private isLoading = false;

  public hasCopyToken = false;

  private showLanguageSelect = false;

  private showEditName = false;

  private languageSelected = '';

  public isBlocked = false;

  public isEditing = false;

  public editableName = '';

  private get url() {
    return `${process.env.VUE_APP_USM_URL}/users`;
  }

  public get plan() {
    return this.mxSession_plan;
  }

  @Watch('languageSelected')
  private changeSelected() {
    if (this.languageSelected !== this.user.language) {
      this.updateLanguage();
      this.user.language = this.languageSelected;
    }
  }

  async created() {
    await Promise.all([this.selectVisibilityHandler(), this.fillSelectDefaultOption()]);
    await this.fetchPlanStatus();
    this.editableName = this.user.name;
  }

  startEditing() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.editableName = this.user.name;
  }

  async confirmEdit() {
    if (this.editableName.trim() === '') {
      logger.error('ACCOUNT.EDIT_NAME.INPUT_ERROR');
      return;
    }

    this.isLoading = true;
    this.isEditing = false;

    const getToken = () => {
      const { credential: { jwtData: { token } } } = autentication.getSession();
      return `Bearer ${token}`;
    };

    const headers = {
      Authorization: getToken(),
    };

    try {
      await this.$http.patch(`${process.env.VUE_APP_USM_URL}/users`, {
        name: this.editableName,
      }, { headers });

      this.user.name = this.editableName;
      this.isEditing = false;
      FeedbackService.toastr.show('success', this.$t('ACCOUNT.EDIT_NAME.TOAST_SUCCESS'));
    } catch (error: any) {
      logger.error(`${error}`, error);
      FeedbackService.toastr.show('danger', this.$t('ACCOUNT.EDIT_NAME.TOAST_ERROR'));
    } finally {
      this.isLoading = false;
    }
  }

  private async fetchPlanStatus() {
    try {
      const context = await customerDataService.getContext();
      this.isBlocked = context.planStatus === 'BLOCKED';
    } catch (error: any) {
      logger.error(`${error}`, error);
    }
  }

  private mapPlan(value: string): string {
    return ['INTERNAL', 'CHANNEL'].includes(value) ? 'ENTERPRISE' : value;
  }

  private fillSelectDefaultOption() {
    this.languageSelected = this.user.language;
  }

  private async selectVisibilityHandler() {
    this.showLanguageSelect = await this.$canIViewFeature({ name: 'language-select' });
    this.showEditName = await this.$canIViewFeature({ name: 'customer-area-edit-name' });
  }

  private async updateLanguage(): Promise<void> {
    this.isLoading = true;
    try {
      await this.$http.patch(this.url, this.buildLanguageRequestData());
      this.$alert.success({
        title: `${this.$t('ACCOUNT.LANGUAGE_CHANGED')}`,
        message: `${this.$t('ACCOUNT.LANGUAGE_UPDATED_SUCCESS')}`,
      });
    } catch (error) {
      this.$alert.warning({
        title: `${this.$t('ACCOUNT.LANGUAGE_CHANGED')}`,
        message: `${this.$t('ACCOUNT.LANGUAGE_UPDATED_FAILED')}`,
        duration: 5000,
      });
    } finally {
      this.isLoading = false;
    }
  }

  private buildLanguageRequestData(): object {
    return { language: this.languageSelected };
  }
}
