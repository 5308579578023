
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';
import { AxiosRequestConfig } from 'axios';
import Select, { ISelectOptions } from '@/components/form/Select.vue';
import i18n from '@/language';
import { ICountry } from '@/types/country';

interface ISelectOptionsTranslations {
  value: string;
  translations: { [language: string]: string };
}

@Component({
  components: { Select },
})
export default class SelectCountry extends Vue {
  @Prop({
    required: true,
    type: String,
  }) readonly id!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly value!: string;

  @Prop({
    required: true,
    type: String,
  }) readonly label!: string;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly disabled!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly error!: boolean;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly errorMessage!: string;

  @Prop({
    required: false,
    type: String,
    default: '',
  }) readonly helper!: string;

  public selected = '';

  public loading = false;

  private optionsTransations: ISelectOptionsTranslations[] = [];

  private get language(): string {
    try {
      return i18n.locale;
    } catch (error) {
      return 'en';
    }
  }

  public get options(): ISelectOptions[] {
    return this.optionsTransations
      ?.map((el: ISelectOptionsTranslations): ISelectOptions => ({
        value: el.value,
        label: el.translations[this.language] || el.translations.en,
      }))
      .sort((a: ISelectOptions, b: ISelectOptions): number => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        return Number(labelA > labelB) || Number(labelA === labelB) - 1;
      });
  }

  @Watch('value')
  private changeValue(newValue: string) {
    this.selected = newValue;
  }

  @Watch('selected')
  private changeSelected(newValue: string) {
    this.$emit('input', newValue);
  }

  created() {
    this.buildCountryOptions();
  }

  private async buildCountryOptions(): Promise<void> {
    const countries = await this.getCountryList();

    this.optionsTransations = countries
      ?.filter((el: ICountry): boolean => !!(el.countryCode && el.translations))
      ?.map((el: ICountry): ISelectOptionsTranslations => {
        const { translations } = el;
        return {
          value: el.countryCode,
          translations,
        };
      });
  }

  private async getCountryList(): Promise<ICountry[]> {
    this.loading = true;
    try {
      const url = `${process.env.VUE_APP_CUSTOMER_PERSONAL_DATA_URL}/v1/resources/countries`;

      const options: AxiosRequestConfig = {
        params: {
          fields: 'countryCode,translations',
        },
      };

      const { data } = await this.$http.get<ICountry[]>(url, options);

      return data;
    } catch {
      this.selected = '';

      return [];
    } finally {
      this.loading = false;
    }
  }
}
